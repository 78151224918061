import { useCallback } from 'react';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToFn, NavigateToParams } from 'root/clientTypes';
import { TabsVariant, type DropdownOptionProps, type TabItem } from 'wix-ui-tpa/cssVars';
import { useGetDesignStylesParams } from 'root/hooks/useGetDesignStylesParams';
import { useGetNavigationAlignment } from 'root/hooks/preset/useGetNavigationAlignment';
import { useGetNavigationLayout } from 'root/hooks/preset/useGetNavigationLayout';
import { useGetNavigationFullWidthTab } from 'root/hooks/preset/useGetNavigationFullWidthTab';
import { buildMenuUrlFromCurrentUrl } from 'root/utils/navigation';

type UseNavigationLogicParams = {
  menus: PopulatedMenu[];
  setActiveItem: (index: number) => void;
  navigateTo: NavigateToFn;
};

export const useNavigationLogic = ({ menus, setActiveItem, navigateTo }: UseNavigationLogicParams) => {
  const { preset } = useGetLayoutStylesParams();
  const { navigationDropdownStyle } = useGetDesignStylesParams();

  const navigationAlignment = useGetNavigationAlignment(preset);
  const navigationLayout = useGetNavigationLayout(preset);
  const navigationFullWidthTab = useGetNavigationFullWidthTab(preset);

  const getMenuNavigationDetailsByIndex = useCallback(
    (index: number): NavigateToParams => {
      const menu = menus[index];
      const menuId = menu.id;
      const urlQueryParam = menu.urlQueryParam;
      return { menuId, urlQueryParam, isFirst: index === 0 };
    },
    [menus]
  );

  const tabsWidth = navigationFullWidthTab ? TabsVariant.fullWidth : TabsVariant.fit;
  const tabOptions = menus.map((menu: PopulatedMenu, index: number): TabItem => {
    const href = buildMenuUrlFromCurrentUrl(getMenuNavigationDetailsByIndex(index));
    return {
      title: menu?.name,
      id: index.toString(),
      href,
    };
  });

  const dropdownOptions: DropdownOptionProps[] = tabOptions.map(
    (option): DropdownOptionProps => ({
      id: option.id,
      value: option.title,
      isSelectable: true,
    })
  );

  const onClickTab = (index: number) => {
    setActiveItem(index);
  };

  const onClickNavigationDropdownOption = (option: DropdownOptionProps) => {
    onClickTab(Number(option.id));
    navigateTo(getMenuNavigationDetailsByIndex(Number(option.id)));
  };

  return {
    navigationLayout,
    tabsWidth,
    dropdownOptions,
    tabOptions,
    navigationAlignmentString: navigationAlignment,
    onClickNavigationDropdownOption,
    onClickTab,
    navigationDropdownStyle,
  };
};
